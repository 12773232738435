.ngb-dp-header {
    font-size:10px;
}

.ngb-dp-weekday{
    font-family: $font-family-sans-serif;
    font-style: unset;    
    font-size: 10px;
}

.date-picker-wrapper {
    .datepicker-button {
        font-size: 20px;
        padding: 10px;
        background: $input-bg;
        color: $bold-text-color;
        cursor: pointer;
    }
}