.d3-login-info {
  padding-left: 10px;
  padding-top: 10px;
  background-image: url('/assets/images/btm_gradient.png');
  background-repeat: repeat-x;
  background-size: contain;

  .img-thumbnail {
    vertical-align: top;
    max-width: $login-info-img-thumbnail-height;
    padding: 0;
  }

  .login-user {
    position: relative;

    .login-user--name {
      @media screen and (max-width: 768px) {
        display: none;
      }

      margin-bottom: 0.2rem;
      font-size: 14px;
      font-weight: bold;
      color: #F7F7F8;
    }
    .login-user--company {
      @media screen and (max-width: 768px) {
        display: none;
      }

      margin-bottom: 0.2rem;
      font-size: 11px;
      font-weight: normal;
      color: #F7F7F8;
    }

    .login-user--actions {
      font-size: 11px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      @media screen and (min-width: 769px) {
        margin-bottom: 15px;
        flex-direction: row;
      }
    }

    .btn-link {
      border-style: none;
      border-color: transparent;
      color: #9D9D9D;
      font-weight: bold;
      display: inline-flex;
      align-items: center;
      padding: 3px;
      background-color: transparent;

      .btn--label {
        @media screen and (max-width: 768px) {
          display: none;
        }
      }

      &:hover {
        color: $primary;

        .login-user--icon__account,
        .login-user--icon__logout {
          svg path {
            fill: $primary;
          }
        }
      }
    }

    .login-user--divider {
      width: 2px;
      background-color: #9D9DA0;
      height: 20px;
    }
  }

  &.custom {
    padding: 16px;
    background-image: none;
    .login-user {
      position: relative;
  
      .login-user--name {
        @media screen and (max-width: 768px) {
          display: none;
        }
  
        margin-bottom: 0.2rem;
        font-size: 14px;
        font-weight: 500;
        text-transform: uppercase;
        color: #F7F7F8;
      }
      .login-user--company {
        @media screen and (max-width: 768px) {
          display: none;
        }
  
        margin-bottom: 0.2rem;
        font-size: 14px;
        padding-bottom: 10px;
        font-weight: 500;
        text-transform: capitalize;
        color: #6b778b;
      }
  
      .login-user--actions {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
  
        @media screen and (min-width: 769px) {
          padding-top: 15px;
          margin-bottom: 0;
          border-top: 1px solid #283956;
          flex-direction: row;
        }
      }
  
      .btn-link {
        border-style: none;
        border-color: transparent;
        color: #9D9D9D;
        font-weight: 500;
        font-size: 13px;
        text-transform: uppercase;
        display: inline-flex;
        align-items: center;
        padding: 3px;
        background-color: transparent;
  
        .btn--label {
          @media screen and (max-width: 768px) {
            display: none;
          }
        }
  
        &:hover {
          color: $primary;
  
          .login-user--icon__account,
          .login-user--icon__logout {
            svg path {
              fill: $primary;
            }
          }
        }
      }
  
      .login-user--divider {
        border-right: $white;
        height: 30px;
      }
    }
  }
}
