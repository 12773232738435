@import "../../../styles/global/colors";

.d3-sidebar {
  display: flex;
  flex-direction: column;
  height: 100%;
  font-family: $font-family-regular;
  color: $sidebar-menu-text-color;
  background-color: $sidebar-base-bg-color;
  line-height: 1.5;

  &__header {
    flex: 0 0 $sidebar-width;
    background: $sidebar-header-bg-color url("/assets/images/logo.png")
      no-repeat center / contain;
  }

  &__body {
    flex: 1 1 0;
    overflow-y: auto;

    .nav {
      &--main {
        padding-block: spacing(5);
        gap: spacing(3);
      }

      .nav-link {
        padding: spacing(1) spacing(6);

        [class^="icon-"] {
          display: none;
        }
      }

      li.firstLevel {
        margin: 0;
        font-size: 18px;
        font-weight: 700;
        color: $sidebar-menu-title-text-color;
      }

      li.isOtherLevel {
        padding-bottom: 0;
        font-size: 14px;
        font-weight: 400;
        color: $sidebar-menu-text-color;

        .nav-link {
          padding: spacing(1) spacing(8);
        }

        &:hover {
          color: $sidebar-menu-bg-active-color;
        }

        &.isActive {
          font-weight: 700;
          color: $sidebar-menu-text-active-color;
          background-color: $sidebar-menu-bg-active-color;
        }
      }
    }
  }

  &__footer {
    flex: 0 0 max-content;
    border-top: 1px solid $sidebar-border-color;
    padding: spacing(4);
  }

  &__username {
    margin-bottom: spacing(2);
    font-size: 18px;
    font-weight: 700;
  }

  &__role {
    margin-bottom: spacing(4);
    font: 400 14px / 1.2 $font-family-base;
  }

  &__sign-in-out {
    display: flex;
    gap: spacing(2);

    .btn {
      flex: 1 1 50%;
      font-size: 20px;
    }
  }
}
