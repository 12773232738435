.title {
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 1.1;
}

.btn-success {
  font-family: $font-family-sans-serif;
  border-radius: 3px;
  background-color: $dark;
  font-size: 13px;
  font-weight: bold;
  color: $primary;
  // border: none;

  &:hover,
  &:active,
  &:focus {
    background-color: $dark;
    color: $white;
  }
}

.title-large {
  color: $card-title-color;
  font-size: $card-title-large-font-size;
  font-weight: $bold;
  margin-bottom: 0;
}

.title-md {
  color: $card-title-color;
  font-size: $card-title-md-font-size;
  font-weight: $bold;
  margin-bottom: 0;
}

.title-md-default {
  color: $card-title-color;
  font-size: $font-size-sm;
  font-weight: $bold;
  margin-bottom: 0;
}

.text-md {
  font-size: $md-label-font-size;
  margin-bottom: 0;
}

.title-small {
  font-size: $small-title-font-size;
  margin-bottom: 0;
}

.title-small-light {
  color: $color-default;
  font-size: $small-title-font-size;
  font-weight: $font-weight-light;
  margin-bottom: 0;
}

.title-xs {
  color: $dark;
  font-size: $small-label-font-size;
  margin-bottom: 0;
}

.text-bold {
  font-weight: $bold;
  color: $bold-text-color;
}

.text-color-bold {
  color: $bold-text-color;
}

.text-color-white {
  color: white;
}

.text-color-red {
  color: $red !important;
}

.cursor-pointer {
  cursor: pointer;
}

.title-small-gray {
  @extend .title-small;
  color: $title-gray-color;
}

p.multiline-text {
  white-space: pre-wrap;
}

.bpp-container-default {
  padding: 10px;
}

.label-small {
  color: $bold-text-color;
  font-size: $small-label-font-size;
  font-weight: $bold;
  margin-bottom: 0;
}

.label-lg {
  color: $bold-text-color;
  font-size: $lg-label-font-size;
  font-weight: $bold;
  margin-bottom: 0;
}

.text-small {
  font-size: $small-label-font-size;
  font-weight: $font-weight-normal;
  margin-bottom: 0;
}

.border-bottom-thin {
  border-bottom: 1px solid $input-btn-secondary-border-color;
}

.color-icon {
  color: $primary-dark;
}

.color-default {
  color: $color-default;
}

.color-panel {
  color: $panel-title-color;
}

.color-title {
  color: $card-title-color;
}

.color-add-icon {
  color: $primary;
}

.color-remove-icon {
  color: $icon-remove-color;
}

.background-color-secondary {
  background-color: $secondary;
}

.default-border {
  border-color: $input-btn-secondary-border-color;
  border-radius: 4px;
}

.grayscale-bg {
  background-color: $grayscale-90;
}

.white-bg {
  background-color: $white;
}

.color-action-icon {
  color: $action-icon-color;

  &:hover,
  &:focus {
    color: $dark;
  }
}

.input-control-common {
  width: 160px;
  height: 40px !important;
}

.top-navbar {
  .dropdown {
    height: 102px;

    button {
      &.dropdown-toggle {
        &::after {
          font-size: 26px !important;
          top: 38px;
        }
      }

      &.leaguelogo {
        background-color: $primary-dark !important;
        width: 100%;
        background-image: url("/assets/images/toggle-bg.svg");
        background-repeat: no-repeat;
        background-position-x: right;
        height: 102px;
        max-height: 102px;
        color: $primary-dark !important;
        border: unset;
        border-radius: 0;

        .bigLion {
          margin-top: 6px;
          margin-left: 10px;
          background-image: url("/assets/images/lion.png");
          background-size: contain;
          background-repeat: no-repeat;
          background-position-x: left;
          height: 65px;
          padding-top: 5px;

          .desc {
            color: white;
            font-size: 28px;
            font-weight: bold;
            margin-left: 60px;
          }

          .subDesc {
            color: #b4a3b1;
            margin-left: 30px;
            font-size: 11px;
            font-weight: bold;
            padding-top: 4px;
          }
        }

        &:hover,
        &:focus,
        .focus {
          opacity: 0.9;
        }
      }
    }
  }
}

.d3-titlebar {
  padding: 1.6rem 0rem 1.2rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > div {
    display: inline-block;
  }

  .navigation-link {
    position: absolute;
    right: 20px;
    top: 11px;

    .btn-link {
      color: $dark;
      font-size: 0.875rem;
      padding: 0;

      [class^="icon-"] {
        padding-left: 15px;
        padding-right: 10px;
      }

      .count {
        position: relative;
        top: -5px;
      }
    }
  }

  .fds-title {
    display: flex;
    align-items: center;
    [class^="icon-"] {
      color: $primary;
      font-size: 30px;
      position: relative;
      top: 2px;
    }

    h3 {
      font-size: 32px;
      color: black;
      margin-bottom: 0;
      text-transform: uppercase;
    }
  }
}

.bpp-card {
  @extend .card;
  width: 100%;
  border: none !important;
  background-color: transparent !important;

  .card-body {
    padding: 1.8rem 2rem;

    .info-text {
      font-family: $font-family-secondary;
    }
  }
  .title {
    font-size: 24px;
  }

  .card-header {
    @extend .card-header;
    background-color: $interviews-dark-gray;
    padding-left: 12px;
    font-size: 18px;
    color: white;
    min-height: 46px;

    .app-interviews &,
    .persons-report & {
      background-color: $interviews-dark-gray;
    }
  }
}

.app-card {
  @extend .card;
  width: 100%;
  border: 0;

  .card-header {
    @extend .card-header;
    font-weight: $bold;
    background-color: #ffffff;
    padding-left: 12px;
    font-size: 18px;
    color: #37003c;
    height: 4rem;
  }
}

.checkbox-position-fix {
  position: relative;
  top: 2px;
  margin-left: 0;
}

.loading {
  position: absolute;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  color: $loading-color;
  font-weight: bold;
  font-size: 18px;

  .spinner-block-wrapper {
    margin-bottom: 10px;
  }
}

.component-spinner {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;

  .spinner-block-wrapper {
    top: 50%;
    left: 50%;
    position: absolute;

    @media (min-width: 768px) {
      left: calc(50% + 120px);
    }
    .spinner-block {
      border-color: transparent $white $white !important;
    }
  }
}

// DASHBOARD PAGINATION
.dashboard-pagination {
  //margin-right: 15px;
  //margin-left: 15px;
  margin-top: 2rem;

  .ngx-pagination {
    @media (max-width: 768px) {
      display: flex;
      justify-content: space-between;
      align-content: center;
      align-items: center;
      padding-left: 0;
      padding: 0.5rem 0;
    }

    li {
      @media (max-width: 400px) {
        font-size: 0;
      }

      &.pagination-next,
      &.pagination-previous {
        @media (max-width: 400px) {
          white-space: nowrap;
          font-size: 13px;

          &:before,
          > a:after {
            font-size: 15px;
          }
        }
      }
    }

    .current {
      @media (max-width: 400px) {
        padding: 0;
      }

      // background: #5c0666;
      // border-bottom: 3px solid #28bb73;
    }

    a {
      //color: #5c0666;
      &:hover {
        // background: #5c0666;
        // color: white;
      }
    }

    button:hover {
      //background: #5c0666;
      //color: white;
    }
  }
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

select.form-control {
  height: inherit !important;
}

.search-picker {
  max-width: 300px;
  background-color: $interviews-blue;

  cursor: pointer;

  .select-place-holder {
    color: white;
    font-weight: 400;
    font-size: 13px;
    text-transform: uppercase;
  }

  .front {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem 1.5rem;

    .arrow {
      float: right;
      font-size: 14px;
      color: white;
      margin-right: 9px;
    }
  }

  .dropdown-menu {
    width: 100%;
    background-color: #ffffff;
    margin: -3px -1px;
    padding: 0;
    border-radius: 0px;
    max-height: calc(100vh - 156px);
    overflow-y: auto;
    min-width: 100% !important;
    background-attachment: local, local, scroll, scroll;

    span {
      //line-height: 40px;
    }

    span.icon-edit {
      float: right;
      color: #490050;
      font-size: 20px;
      cursor: pointer;
    }

    hr {
      margin: 0;
    }

    & > div {
      padding: 0px 9px;
    }

    .add-span {
      color: #29bb73;
      font-weight: bold;
      font-size: 12px;
    }
  }
}

.loading-table {
  height: 50px;
  padding-top: 15px;
}

.flex-row-wrap {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
}

.flex-row-nowrap {
  display: flex;
  flex-direction: row;
}

.form-control {
  line-height: inherit;
  color: $primary-dark;
  &:focus {
    color: $primary-dark;
  }
}

input[type="checkbox"] {
  -webkit-appearance: none;
  background-color: #ffffff;
  border: 1px solid #d7d4d7;
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
  transform: scale(0.6);
}

input[type="checkbox"]:checked {
  background-color: #ffffff;
  border: 1px solid #d7d4d7;
}

input[type="checkbox"]:checked::before {
  background-color: $primary-dark;
}

input[type="checkbox"]:checked ~ .custom-control-label::before {
  background-color: $primary-dark;
}

input[type="checkbox"]:checked::after {
  content: "\e94b";
  font-size: 20px;
  position: absolute;
  top: -2px;
  left: -1px;
  color: $primary-dark;
}

.custom-radio .custom-control-label::before {
  background-color: white;
}

.custom-control-label {
  font-weight: bold;
  font-size: 13px;
  color: $input-btn-secondary-text;
  cursor: pointer;
}

.custom-control-input {
  cursor: pointer;
}

.btn-round-30 {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  padding: 0;
}

/*
.custom-radio .custom-control-input:checked~.custom-control-label::before,
.custom-radio .custom-control-input:checked~.custom-control-label::after {
  content:'';
  display:block;
  width:60%;
  height:60%;
  margin: 20% auto;
  border-radius:50%;
}

.custom-radio .custom-control-input:active~.custom-control-label::before {
  color: #fff;
  background-color: $primary;
}

.custom-radio .custom-control-input:focus~.custom-control-label::before {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(255, 123, 255, 0.25);
} */

.btn-outline-dark {
  color: $primary;
  border-color: $primary;
}

/*.row {
    width: 100%;
}*/
.card-body {
  @media (max-width: 576px) {
    padding: 0.75rem 0.75rem;
  }
}

.accordion-container {
  .card-body {
    padding: 0;
  }
}

.legenda.interview {
  position: relative;
  top: 0px;
  right: 0px;
}

.legenda {
  position: absolute;
  top: 22px;
  right: 70px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  color: $white;
  font-size: 10px;
  z-index: 2;

  @media (max-width: 567px) {
    top: 10px;
    right: 0;
  }

  .legenda--item {
    display: flex;
    padding-right: 10px;
  }

  .legenda--item--dashboard {
    display: flex;
    padding-right: 10px;
    color: $black;
  }

  .offset {
    margin-top: 4px;
    margin-right: 4px;
  }

  .state-container {
    width: 8px !important;

    .state-icon {
      height: 8px !important;
      width: 8px !important;
      border-radius: 50%;
    }
  }
}

.draft {
  font-style: italic;
  color: rgb(0, 0, 0);
  opacity: 0.4;
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }

  .print-height-auto {
    height: auto !important;
  }

  .mainContainer {
    height: auto !important;
  }

  html {
    height: auto !important;
    overflow: auto;
  }
}

.form-check-input {
  margin-left: 0;
  margin-top: 0.2rem;
  cursor: pointer;

  &:disabled {
    cursor: default;
  }
}

.form-check-label {
  margin-left: 1.25rem;
}

.green-line {
  border-bottom: 3px solid $primary;
}

.width-12-per {
  width: 12%;
}

.sticky-header {
  position: sticky;
  top: 0;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
  padding-right: 0;
  padding-left: 0;
}

.row {
  margin-left: 0;
  margin-right: 0;
}
