.locations-picker {
  // width: 100%;
  height: 40px;
  border: 1px solid #EBE9EB;
  border-radius: 3px;
  background-color: #FCFCFC; // cursor: pointer;

  @media (min-width: 768px) {
    width: 250px;
  }

  .select-place-holder {
    color: #605260;
    font-size: 13px;
    margin-left: 9px;
  }

  .front {
    span {
      line-height: 40px;
    }

    .arrow {
      float: right;
      font-size: 14px;
      color: #5e0066;
      margin-right: 9px;
    }
  }

  .dropdown-menu {
    background-color: #FFFFFF;
    padding: 0;
    border-radius: 0px;
    border-top: 2px solid $primary;

    .dropdown-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      line-height: 40px;
    }

    span {
      line-height: 40px;
    }

    span.icon-edit {
      float: right;
      color: #490050;
      font-size: 20px;
      cursor: pointer;
    }

    hr {
      margin: 0;
    }

    &>div {
      padding: 0px 9px;
    }

    .add-span {
      font-weight: bold;
      font-size: 12px;
    }


  }
}