.dashboard-broadcaster-rp {
    .card-header {
        background-color: $white;
        height: 50px;
    }
    .due-time {
        display: block;
        font-size: 10px;
        font-weight: normal;
        color: $gray-text-color-1;
    }
    .team-name {
        display: block;
        font-size: 13px;
        font-weight: bold;
        color: $panel-title-color;
    }
    .sub-title {
        background-color: $white;
        height: 35px;
        padding: 0.75rem 1.25rem;
        span {
            font-size: 12px;
            font-weight: bold;
            color: $week-title-color;
        }
    }
}