@import '../mixins/match';


.section--fixture__past {
  .section--header {
    padding: 10px 10px 10px 16px;
    margin-top: .5rem;
    color: #3e0d42;
    background-color: white;
    border: 1px solid #EBE9EB;
  }

  .section--header__title {
    font-size: 18px;
    font-weight: bold;
  }

  .section--header__subtitle {
    color: #3e0d42;
  }
  // Accordion
  .accordion {
    .accordion--title {
      background-color: white;
      display: flex;
      justify-content: space-between;
      letter-spacing: 0.5px;
      align-items: center;
      height: 50px;
      color: #3E0D42;
      font-size: 14px;
      font-weight: bold;
      padding: 0.75rem 1.25rem;

      &:hover {
        cursor: pointer;
        background-color: #F1F0F1;
        transition: background-color 400ms ease;
      }
    }
    // CARD
    .card-header {
      //background-color: #ffffff;
      padding: 0;
    }

    .card-body {
      //padding: 0;
      //background-color: #F1F0F1;
    }
  }
}

.fx-match-header {
  padding: 0.75rem 1.25rem;
  background-color: white;
  @include match-header(small);
}

.fx-match-header-small {
  padding: 0rem 1.25rem;
  background-color: white;
  @include match-header(small);
}

.fx-match-detail {
  padding: 0.75rem 1.25rem;
  background-color: #FCFCFC;
  border-top: 1px solid #EBE9EB;
  font-size: 13px;
  // margin-bottom: 1rem;
  // Team Header Mobile
  .fx-header {
    font-size: 14px;
    font-weight: bold;
    color: #5d0666;
    border-bottom: 1px solid #ebe9eb;
    padding: .5rem 0;
    text-align: center;
    margin-bottom: .5rem;

    .title-icon {
      width: 26px;
      margin-right: .5rem;
    }
  }

  @media (min-width: 768px) {
    .fx-detail__home {
      padding-right: 1%;

      > .row {
        text-align: right;
      }

      .col-lg-4 > .row {
        flex-direction: row-reverse;
      }
    }

    .fx-detail__away {
      padding-left: 1%;

      > .row {
        flex-direction: row-reverse;
      }
    }
  }
}


.fx-wrapper {

  //background-color: white;
  border-bottom: 1px solid #EBE9EB;
}

.fx-status-wrapper > .row {
  margin: .5rem 0;
  padding: .5rem;
  flex-direction: column;
  margin-left: 24px;

  @media (min-width: 400px) {
    text-align: center;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 0;

  }
}

.fx-status-wrapper {
  margin: .5rem 0;

}

.fx-details-wrapper {
  background-color: #FCFCFC;
  border-top: 1px solid #EBE9EB;
}

.fx-container {
  padding: 0.75rem 1.25rem;

  @media (min-width:568px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fx-title {
    color: #3E0D42;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.5px;
    line-height: 16px;
    //min-width: 200px;
    // cursor: pointer;
  }



  .fx-icon {
    height: 20px;
  }



  .fx-item-same {
    flex-basis: 64%;
  }



  .fx-date {
    opacity: 0.8;
    color: #605260;
    font-family: $font-family-sans-serif;
    font-size: 10px;
    line-height: 11px;
  }

  .fx-offset {
    margin-left: 30px;
    margin-right: 30px;
  }
}

.accordion-fx-wrapper {
  border-bottom: 1px solid #EBE9EB;
  margin-bottom: 1rem;
}

.accordion-fx-wrapper.with-events {
  cursor: pointer;
  @media (min-width: 1024px) {
    &:hover {

      // background-color: rgba(217, 216, 218, 0.05);
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
      transition: all 400ms ease;
    }
  }
}

.fx-start {
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
}

.fx-end {
  @media (min-width: 768px) {
    justify-content: flex-end;
  }
}

.fx-between {
  @media (min-width: 768px) {
    justify-content: space-between;
  }
}

.fx-block-r {
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: 20px;
  }
}

.fx-block-l {
  @media (min-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-right: 20px;
  }
}

.status-text {
  color: #382738;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.39px;
  line-height: 15px;
  //width: 100%;
}

.status-line {
  border-top: 1px solid #EBE9EB;
  margin-top: 16px;
  padding-top: 16px;
}

.fx-group-title {
  color: #3E0D42;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 18px;
  text-align: left;
  padding: 16px;
  background-color: white;
  border-bottom: 1px solid #EBE9EB;
}

.accordion-fx-group-title {
  // background-color: white;
  // height: 50px;
  // color: #3E0D42;
  // font-size: 14px;
  // font-weight: bold;
  // letter-spacing: 0.5px;
  // //margin: -0.75rem -1.25rem;
  // &:hover {
  //     cursor: pointer;
  //     background-color: rgba(245, 245, 245, 0.185);
  //     transition: background-color 400ms ease;
  // }
}

.empty-event-r {
  margin: 6px 0;
  font-size: 11px;
  min-height: 26px;

  @media (min-width: 768px) {
    //flex-grow: 1;
    //text-align: right;
  }
}

.empty-event-l {
  margin: 6px 0;
  font-size: 11px;
  min-height: 26px;

  @media (min-width: 768px) {
    //flex-grow: 1;
    //text-align: left;
  }
}

.width150 {
  min-width: 150px;
}

.width100 {
  min-width: 100px;
}

.list-container {
  height: calc(100vh - 190px - 1.5rem);
  overflow-y: auto;
}