.app-club-training-details {
  margin-bottom: 50px;
  .info-block {
    background: $secondary;
  }

  .info-text-block {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
  // INFO (Last Saved)
  .info-img {
    height: 32px;
    width: 32px;
    display: block;
    margin: auto;

    @media (min-width: 568px) {
      height: 41px;
      width: 41px;
    }
  }

  .info-text {
    display: inline-block;
    vertical-align: middle;
  }

  .middle-text {
    vertical-align: middle;
  }

  .location-control {
    width: 200px;
    height: 40px !important;
  }

  .form-group {
    margin-bottom: 0;
  }

  .margin-right-10 {
    margin-right: 10px;
  }

  .area-icon {
    //margin-left: 8px;
    //margin-right: 8px;
  }

  .notes-area {
    height: 160px;
    //margin-left: 40px;
    //width: calc(100% - 40px);
    background-color: $secondary;
    border-color: $input-btn-secondary-border-color;
  }

  .readonly-notes-area {
    //margin-left: 40px;
  }

  .note-container {
    margin-left: 50px;
    width: calc(100% - 50px);
  }

  .ngb-tp-hour {
    input {
      height: 40px;
    }
  }

  .ngb-tp-minute {
    input {
      height: 40px;
    }
  }

  .icon-edit, .icon-edit:hover {
    color: $panel-title-color;
    cursor: pointer;
  }

  .icon-col {
    float: left;
    width: 54px;
  }

  .after-icon-col {
    width: calc(100% - 54px);
  }

  .by-user {
    font-size: 12px;
    color: $input-btn-secondary-text;
    line-height: 20px;
    margin-left: 10px;
  }
}
