@import '_variables';
@import '../../styles/global/colors';

@font-face {
  font-family: 'icomoon';
  src: url('/assets/icons/icomoon.eot?gzfcen');
  src:
    url('/assets/icons/icomoon.eot?gzfcen#iefix') format('embedded-opentype'),
    url('/assets/icons/icomoon.ttf?gzfcen') format('truetype'),
    url('/assets/icons/icomoon.woff?gzfcen') format('woff'),
    url('/assets/icons/icomoon.svg?gzfcen#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"],
[class*=" icon-"],
[class^="icon-sm-"],
[class*=" icon-sm-"],
input[type="checkbox"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  font-size: $icons-normal-size;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $icons-normal-color;

  &.round {
    border-radius: 50%;
    border: none !important;
  }

  &.red {
    color: $icons-red-normal-color;

    &:hover {
      color: $icons-red-hover-color;
    }

    &.disabled,
    &:disabled {
      color: $icons-disabled-color;
    }
  }

  &.blue {
    color: white;
    background-color: $interviews-blue;

    &:hover {
      color: $icons-red-hover-color;
    }

    &.disabled,
    &:disabled {
      color: $icons-disabled-color;
    }
  }

  &.gray {
    color: $interviews-blue;
    background-color: $interviews-light-gray;
    border: 2px solid rgb(88, 88, 87, 0.5);

    &:hover {
      color: $interviews-dark-gray;
    }

    &.disabled,
    &:disabled {
      color: $icons-disabled-color;
    }
  }

  &.white {
    color: white;
  }

  &:hover {
    //color: $icons-hover-color;
  }

  &.disabled,
  &:disabled {
    color: $icons-disabled-color;
  }
}

[class^="icon-sm-"],
[class*=" icon-sm-"] {
  font-size: $icons-small-size;
}


.icon-videos:before {
  content: "\e700";
}

.icon-graphics:before {
  content: "\e701";
}

.icon-dashboard:before {
  content: "\e900";
}

.icon-activity:before {
  content: "\e901";
}

.icon-calendar:before {
  content: "\e902";
}

.icon-clients:before {
  content: "\e903";
}

.icon-flags:before {
  content: "\e904";
}

.icon-reports:before {
  content: "\e905";
}

.icon-full-screen:before {
  content: "\e906";
}

.icon-chat:before {
  content: "\e907";
}

.icon-teams:before {
  content: "\e908";
}

.icon-user:before {
  content: "\e909";
}

.icon-user-new:before {
  content: "\e90a";
}

.icon-add:before {
  content: "\e90b";
}

.icon-clear:before {
  content: "\e90c";
}

.icon-arrow-down:before {
  content: "\e90d";
}

.icon-arrow-up:before {
  content: "\e90e";
}

.icon-arrow-left:before {
  content: "\e90f";
}

.icon-arrow-right:before {
  content: "\e910";
}

.icon-add-round:before {
  content: "\e911";
}

.icon-play-round:before {
  content: "\e912";
}

.icon-delete-round:before {
  content: "\e913";
}

.icon-delete:before {
  content: "\e914";
}

.icon-info-round:before {
  content: "\e915";
}

.icon-cart:before {
  content: "\e916";
}

.icon-download:before {
  content: "\e917";
}

.icon-edit:before {
  content: "\e918";
}

.icon-folder:before {
  content: "\e919";
}

.icon-pin:before {
  content: "\e91a";
}

.icon-search:before {
  content: "\e91b";
}

.icon-settings:before {
  content: "\e91c";
}

.icon-star-empty:before {
  content: "\e91d";
}

.icon-star:before {
  content: "\e91e";
}

.icon-sync:before {
  content: "\e91f";
}

.icon-time:before {
  content: "\e920";
}

.icon-lion_DarkBG:before {
  content: "\e921";
}

.icon-lion_whiteBG:before {
  content: "\e922";
}

.icon-add-squared:before {
  content: "\e923";
}

.icon-contact:before {
  content: "\e924";
}

.icon-download-round:before {
  content: "\e925";
}

.icon-audio:before {
  content: "\e926";
}

.icon-play:before {
  content: "\e927";
}

.icon-bulletList:before {
  content: "\e928";
}

.icon-file:before {
  content: "\e929";
}

.icon-calendarView:before {
  content: "\e92a";
}

.icon-seat:before {
  content: "\e92b";
}

.icon-calendarWeekly:before {
  content: "\e92c";
}

.icon-services:before {
  content: "\e92d";
}

.icon-teams2:before {
  content: "\e92e";
}

.icon-lock:before {
  content: "\e92f";
}

.icon-fileDownload:before {
  content: "\e930";
}

.icon-preview:before {
  content: "\e931";
}

.icon-place:before {
  content: "\e932";
}

.icon-grid:before {
  content: "\e933";
}

.icon-image_pic:before {
  content: "\e934";
}

.icon-football:before {
  content: "\e935";
}

.icon-play_pic:before {
  content: "\e936";
}

.icon-preview_pic:before {
  content: "\e937";
}

.icon-fileUpload:before {
  content: "\e938";
}

.icon-fileReplace:before {
  content: "\e939";
}

.icon-whistle:before {
  content: "\e93a";
}

.icon-pitch:before {
  content: "\e93b";
}

.icon-countdown:before {
  content: "\e93c";
}

.icon-team-kits:before {
  content: "\e93d";
}

.icon-stat-pack:before {
  content: "\e93e";
}

.icon-world-feed-openers:before {
  content: "\e93f";
}

.icon-live-running-order:before {
  content: "\e940";
}

.icon-logout:before {
  content: "\e941";
}

.icon-music:before {
  content: "\e942";
}

.icon-keyboard:before {
  content: "\e943";
}

.icon-filmingLocation:before {
  content: "\e944";
}

.icon-fixtures:before {
  content: "\e935";
}

.icon-training:before {
  content: "\e947";
}

.icon-add-square-dim:before {
  content: "\e948";
}

.icon-fixturesRequests:before {
  content: "\e94a";
}

.icon-check:before {
  content: "\e94b";
}

.icon-news:before {
  content: "\e929";
}