@import url("https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,100..900;1,100..900&display=swap");

@font-face {
  font-family: "Insatiable Display Condensed";
  src: url("/assets/fonts/InsatiableDisplay-RegularCondensed.woff2")
    format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Insatiable Display Condensed";
  src: url("/assets/fonts/InsatiableDisplay-BoldCondensed.woff2")
    format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Insatiable Display Compressed";
  src: url("/assets/fonts/InsatiableDisplay-RegularCompressed.woff2")
    format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Insatiable Display Compressed";
  src: url("/assets/fonts/InsatiableDisplay-BoldCompressed.woff2")
    format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
