.modal-content {
  .modal-header {
    background-color: $primary;
    color: $white;
    font-size: 14px;

    .close {
      padding: 0.8rem 2rem;
      font-size: 24px;
    }
  }
}