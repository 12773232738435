.user-profile {
  .user-label {
    color: #3E0D42;
    font-size: 11px;
    font-weight: bold;
    letter-spacing: 0.39px;
    line-height: 15px;
  }

  .user-item {
    height: 40px;
    // width: 400px;
    border: 1px solid #EBE9EB;
    border-radius: 3px;
    background-color: #FCFCFC;
    margin-bottom: 22px;
  }

  .user-text {
    color: #605260;
    font-size: 13px;
    font-weight: bold;
    margin-left: 8px;
  }

  .notification {
    margin-top: 36px;

    .content {
      margin-top: 1px;
    }

    .title {
      color: #3E0D42;
      font-size: 14px;
      font-weight: bold;
      border-radius: 3px 3px 0 0;
      box-shadow: 0 1px 0 0 rgb(196, 196, 196);
      padding-bottom: 10px;
    }

    .options-title {
      margin: 12px 0 10px 0;
    }

    .wrapper {
      padding-bottom: 16px;
      background-color: #FCFCFC;
      padding: 10px;
    }

    .type-item {
      color: #3E0D42;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 16px;
      border-right: 1px solid #EBE9EB;
    }

    .option-header {
      color: #3E0D42;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 0.39px;
      line-height: 15px;
      

      @media (max-width: 768px) {
        padding-right: 10px;
        text-align: right;
      }
    }
  }
}
