.main-layout {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .sidebar {
    flex: 0 0 $sidebar-width;
  }

  .mainContainer {
    padding: 0 10px 10px 0;
    width: 100%;
    z-index: 0;
    height: 100vh;
    margin-left: 50px;

    @media (min-width: 769px) {
      margin-left: 0;
      width: calc(100% - 230px);
    }

    &__topbar {
      height: $maincontainer-topbar-height;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 spacing(7);
      background-color: $sidebar-base-bg-color;

      .nav {
        gap: spacing(7);
        font: 400 16px / 1 $font-family-regular;

        &.-downloads {
          margin-left: auto;
        }
      }

      .nav-link {
        display: flex;
        align-items: center;
        gap: spacing(2);
        padding: spacing(2);
        color: $sidebar-menu-text-color;
        cursor: pointer;

        &:hover {
          color: $sidebar-menu-bg-active-color;
        }

        &.isActive {
          font-weight: 700;
          color: $sidebar-menu-text-active-color;
          background-color: $sidebar-menu-bg-active-color;

          .count {
            color: $sidebar-menu-text-color;
            background-color: $sidebar-base-bg-color;
          }
        }

        .count {
          display: grid;
          place-content: center;
          width: spacing(4);
          height: spacing(4);
          border-radius: 50%;
          font-size: 10px;
          color: $sidebar-menu-text-active-color;
          background-color: $sidebar-menu-bg-active-color;
        }
      }
    }
  }
}
