.players-list {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 568px) {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .players-list--item {
    flex-basis: calc(100% - 30px);
    margin-bottom: 1rem;

    @media (min-width: 568px) {
      flex-basis: 48%;
      margin-left: 2%;

      &:nth-child(2n+1) {
        margin-left: 0;
      }
    }

    @media (min-width: 1024px) {
      flex-basis: 31.33333%;

      &:nth-child(2n+1) {
        margin-left: 2%;
      }

      &:nth-child(3n+1) {
        margin-left: 0;
      }
    }

    @media (min-width: 1400px) {
      flex-basis: 23%;

      &:nth-child(3n+1) {
        margin-left: 2%;
      }

      &:nth-child(4n+1) {
        margin-left: 0;
      }
    }
  }
}


.app-player-card {
  width: 100%;
  border: 1px solid #EBE9EB;
  border-radius: 3px;
  //margin: 10px 10px 0 0;
  transition: box-shadow 400ms ease, transform 400ms ease;

  

  .player-content {
    padding: 2px 16px 2px 16px;

    .header {
      margin: 8px 0 4px 0;

      .player-name {
        font-size: 14px;
        font-weight: bold;
        color: $primary-dark;
      }

      .manager-name {
        color: $primary-dark;
        font-size: 11px;
        margin-left: 10px;
      }
    }

    .player-info-block {
      padding: 4px 0px 0px 0px;
      border-top: 1px solid #F1F0F1;

      .info-item {
        margin-right: 16px;

        .parameter {
          font-size: 11px;
          color: $title-gray-color;
        }

        .statistic {
          font-size: 11px;
          color: $title-gray-color;
          font-weight: bold;
        }
      }
    }

    .player-icon {
      cursor: pointer;
      color: $primary-dark;
    }
  }
}

.app-player-card.player-manager {

    &:hover,
    &:active,
    &:focus {
        box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.05);
        transform: translate(0px, -6px);
        background-color: #F7F7F8;
        border: 1px solid #D7D4D7;
    }
    &:hover .player-name {
        color: #29BB73;
    }
}
