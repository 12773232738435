@mixin request() {
    .request-wrapper {
        padding-top: 16px;
        background-color: white;
        margin-bottom: 64px;
        border-top: 1px solid #EDEDED;
        border-bottom: 1px solid #EDEDED;

        .header {
          margin-top: 15px;
          padding-bottom: 15px;
          // border-bottom: 1px solid #EDEDED;
        }

        .title-text {
          color: #3E0D42;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.5px;
          line-height: 18px;
        }

        .subtitle-text {
          color: $title-gray-color;
          font-size: 12px;
          letter-spacing: 0.5px;
          line-height: 18px;
        }

        .section-wrapper {
          padding-top: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid #EDEDED;

          @media (min-width: 768px) {
            padding-top: 30px;
            padding-bottom: 30px;
          }

          @media (min-width: 1024px) {
            padding-top: 40px;
            padding-bottom: 40px;
          }


          .title-wr {
            margin-top: 8px;
            margin-bottom: 10px;

            .title-text {
              color: #3E0D42;
              font-size: 14px;
              font-weight: bold;
              letter-spacing: 0.5px;
              line-height: 18px;
            }
          }

          .form-check-label {
            opacity: 0.8;
            color: #382738;
            font-size: 13px;
            font-weight: bold;
            letter-spacing: 0.46px;
            line-height: 18px;
          }

          .details-wrapper {
            margin-top: 16px;
            padding: 20px 0;
            border-bottom: 1px solid #EDEDED;
            border-top: 1px solid #EDEDED;
            background-color: #FCFCFC;

            .title {
              color: #3E0D42;
              font-size: 11px;
              font-weight: bold;
              letter-spacing: 0.39px;
              line-height: 15px;
            }

            .subtitle {
              color: $title-gray-color;
              font-size: 11px;
              font-weight: bold;
              letter-spacing: 0.69px;
              line-height: 22px;
            }

            .action-text {
              margin-left: 12px;
              color: black;
              font-size: 12px;
              font-weight: bold;
              letter-spacing: 0.75px;
              line-height: 14px;
            }

            .item-wrapper {
              border: 1px solid lightgray;
              margin-bottom: 16px;

              .item {
                margin: 12px;
              }

              .item-name {
                color: #3E0D42;
                font-size: 13px;
                font-weight: bold;
                letter-spacing: 0.5px;
                line-height: 16px;
              }

              .item-info {
                opacity: 0.8;
                color: #382738;
                font-size: 11px;
                letter-spacing: 0.55px;
                line-height: 18px;
              }
            }
          }
        }

        .request-footer {
          margin-top: 22px;

          .btn {
            height: 30px;
            border-radius: 3px;
            font-size: 12px;
            font-weight: bold;
          }

          .btn-outline-dark {
            color: #382738;
            border: 1px solid #EBE9EB;
            background-color: #FFFFFF
          }
        }

        .additional-notes {
          color: #3E0D42;
          font-size: 11px;
          font-weight: bold;
          letter-spacing: 0.39px;
          line-height: 15px;
          margin-bottom: 5px;
        }

        .notes-area {
          border: 1px solid #EBE9EB;
          border-radius: 3px;
          background-color: #FCFCFC;
          width: 100%;
          height: 160px;
        }

        .accordion-wrapper {
          .header-row {
            padding: 10px 0;
          }

          .broadcaster-name {
            color: #3E0D42;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 0.5px;
            line-height: 18px;
          }

          .request-date {
            color: #382738;
            font-size: 11px;
            letter-spacing: 0.55px;
            line-height: 18px;
          }

          .request-status {
            color: $title-gray-color;
            font-size: 11px;
            font-weight: bold;
            letter-spacing: 0.69px;
            line-height: 13px;
          }
          .btn-link {
            box-shadow: none;
            width: 100%;
          }
        }
      }

}
