.app-filming {
  @include match-header();

  .brd-b-default {
    border-bottom: 1px solid $default-border-color !important;
  }

  .title-teams {
    width: 100%;

    .title-icon {
      height: 26px;
    }
  }

  .section--fixture__past {
    .section--header {
      padding: 10px 10px 10px 16px;
      margin-top: 0.5rem;
      color: #3e0d42;
      background-color: white;
      border: 1px solid #ebe9eb;
    }

    .section--header__title {
      font-size: 18px;
      font-weight: bold;
    }

    .section--header__subtitle {
      color: #3e0d42;
    }

    // Accordion
    .accordion {
      .accordion--title {
        background-color: white;
        display: flex;
        justify-content: space-between;
        letter-spacing: 0.5px;
        align-items: center;
        height: 50px;
        color: #3e0d42;
        font-size: 14px;
        font-weight: bold;
        padding: 0.75rem 1.25rem;

        &:hover {
          // cursor: pointer;
          background-color: #f1f0f1;
          transition: background-color 400ms ease;
        }
      }

      // CARD
      .card-header {
        //background-color: #ffffff;
        padding: 0;
      }

      .card-body {
        background-color: white;
      }
    }

    .match-header {
      background-color: $header-bg-color;
      color: #3e0d42;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 0.39px;
      line-height: 15px;
      border-bottom: 1px solid $default-border-color;

      .item {
        padding: 1rem 1rem 0.2rem 1rem;
      }
    }

    .match-wrapper {
      border-bottom: 1px solid $default-border-color;
      align-items: center;      

      &:hover {        
        position: relative;
        z-index: 10;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        transform: translateY(-1px);
        transition: all 400ms ease;
      }

      background-color: white;
    }

    .text-semi-item {
      color: #948d94;
      font-size: 11px;
      letter-spacing: 0.35px;
      line-height: 18px;
      text-align: center;
    }

    .text-item {
      color: #382738;
      font-size: 12px;
      letter-spacing: 0.75px;
      line-height: 14px;
    }
  }  
}
