@import "../../styles/global/colors";

// ============================================
// Spacing function
// ============================================
@function spacing($spacing-multiplier) {
  @if not unitless($spacing-multiplier) {
    $spacing-multiplier: strip-units($spacing-multiplier);
  }

  @return $spacing-multiplier * $spacing-token;
}
// spacing token
$spacing-token: 4px;

$grid-columns: 12 !default;
$grid-gutter-width: 0 !default;

//fonts

$font-icon-path: "../assets/icons";
$font-path: "../assets/fonts";

$font-family-regular: "Insatiable Display Condensed";
$font-family-compressed: "Insatiable Display Compressed";
$font-family-secondary: "Archivo";
$font-family-base: $font-family-regular;

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;

$btn-font-weight: $font-weight-bold;
$btn-font-weight-sm: $font-weight-bold;
$bold: bold;

// sizes
$card-title-font-size: 18px;
$card-title-md-font-size: 16px;
$card-title-large-font-size: 24px;
$small-title-font-size: 13px;
$small-label-font-size: 12px;
$md-label-font-size: 13px;
$lg-label-font-size: 13px;
$font-size-base: 1rem !default; // Assumes the browser default, typically `16px`

$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * 0.875) !default;
$input-btn-padding-x: 1.875rem !default;
$input-btn-padding-y-sm: 0.5rem !default;
$input-btn-padding-y: 0.8125rem !default;
$input-btn-line-height: 0.8125 !default;
$btn-border-radius: 0.1875rem !default;

$thumbnail-border-radius: 0px;
$input-btn-border-width: 1px !default;
$card-inner-border-radius: 0px;
$input-btn-border-width: 1px !default;

// colors
// LFP
$blue: #05f0ff;
$purple: #e2de00;
$red: #ff005a;
$yellow: #ece609;
$green: #00ff87;
$white: #ffffff;
$grey: #d9d9d9;
$dark: $interviews-dark-gray;
$primary: #085fff;
$primary-dark: $interviews-dark-gray;
$warning: #f62e00;
$secondary: $white;
$default-bg: $white;
$hover-color: $interviews-gray;
$alerts-header: $interviews-dark-gray;
$default_bg_color: $white;
$campaign-publish_color: $dark;
$color_2: #697589;
$color_1: #ffffff;
$section-title-color: $color_1 !default;

//////////////////////////

$light-gray: #f2f3f3;
$sidebar-hover-color: #1b2875;
$header-title-color: #1b2875;

$icons-hover-color: #29bb73;
$link-hover-color: #29bb73;
$icons-red-hover-color: #05f0ff;
$card-title-color: $primary-dark;
$panel-title-color: $primary-dark;
$bold-text-color: $primary-dark;
$color-default: #212529;
$title-gray-color: #4a5b75;
$week-title-color: #5e0066;
$action-icon-color: #8d4c93;

$font-size-base: 0.8125rem; // Assumes the browser default, typically `16px`
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * 0.875);
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$btn-font-weight: $font-weight-bold;
$btn-font-weight-sm: $font-weight-bold;
$input-btn-padding-y: 0.8125rem;
$input-btn-line-height: 0.8125;
$btn-border-radius: 0.1875rem;
$input-btn-focus-color: $grey;
$input-btn-disabled-color: #f2f3f3;
$input-btn-disabled-text: #afa9af;
$input-btn-disabled-border-color: #d7d4d7;
$input-btn-border-width: 1px;
$input-btn-secondary-color: #ffffff;
$input-btn-secondary-text: #382738;
$input-btn-secondary-border-color: #ebe9eb;
$input-btn-secondary-hover-border-color: #afa9af;
$input-btn-secondary-disabled-color: #ffffff;
$input-btn-secondary-disabled-text: #afa9af;
$input-btn-secondary-disabled-border-color: #ebe9eb;
$input-btn-padding-x: 1.875rem;
$input-btn-padding-y-sm: 0.5rem;
$link-color: $primary;
$link-hover-color: $grey;
$link-hover-decoration: none;
$btn-link-disabled-color: #afa9af;
$input-color: $purple;
$input-focus-color: $purple;
$input-focus-border-color: transparent;
$input-placeholder-color: #bdb8bd;
$input-bg: #f2f2f3;
$input-focus-bg: #f2f2f3;
$input-btn-border-width: 1px;
$input-border-color: #ecebec;
$input-checkbox-disabled: #f2f2f3;
$input-checkbox-disabled-text: #c5c0c5;
$message-background: $dark;
$message-color: #fff;
$login-background-hover: rgba(255, 242, 122, 0.25);
$login-info-color: #f7f7f8;
$login-info-btn-color: #9d9d9d;
$login-info-btn-active-color: #05f0ff;
$menu-item-clickable-default-color: #9d9d9d;
$menu-item-default-color: #767676;
$multiselect-border-color: #e2e0e2;
$multiselect-badge-pill-background: #f1f0f1;
$multiselect-badge-pill-color: #6c556e;
$multiselect-dropdown-icon-color: $btn-link-disabled-color;
$form-control-label-text: 0.6875rem;
$form-control-label-color: #887d88;
$form-control-label-margin-bottom: 0.4rem;
$form-control-disabled-bg: #f9f9f9;
$form-control-disabled-color: #afa9af;
$form-control-disabled-border-color: #f5f4f5;
$one-column-page-background: #f1f0f1;
$two-column-page-background: $one-column-page-background;
$search-selected-color: #16181b;
$search-selected-background: #f8f9fa;
//ICONS
$icons-normal-size: 24px;
$icons-small-size: 16px;
$icons-red-normal-color: $primary;
$icons-hover-color: $dark;
$icons-hover-dark-color: $dark;
$icons-red-hover-color: $dark;
$icons-disabled-color: #e2dfe2;
$login-info-img-thumbnail-height: 1.846rem;
$search-icon-normal: $grey;
$navbar-dark-active-color: $dark;
$icon-remove-color: #5e0066;

$grid-columns: 12;
$grid-gutter-width: 0;
$default-bg: white;
$default-color: #212529;
$fds-background-color: #f1f0f1;
$fds-default-text: #212529;
$one-column-page-background: $fds-background-color;
$two-column-page-background: $one-column-page-background;
$dark-blue: #1b2875;

$message-background: $dark;
$sidebar-hover-color: #2f3a5b;
$sidebar-menu-color: #6b778b;
$header-title-color: $dark-blue;
$warning-alert-background: #f7f7f8;
$warning-alert-border: #ebe9eb;
$icons-hover-color: $hover-color;
$icons-hover-dark-color: $dark;
$link-hover-color: $hover-color;
$icons-red-hover-color: $hover-color;
$card-title-color: #314588;
$panel-title-color: #1b2875;
$fds-default-text: #212129;
$table-border-color: #d7d4d7;
$table-position-color: #948d94;
$table-color: #605260;
$loading-color: $header-title-color;
$font-icon-path: "/assets/icons";
$font-path: "/assets/fonts";
$thumbnail-border-radius: 0px;
$card-inner-border-radius: 0px;
$icons-normal-color: $primary-dark;
$search-icon-normal: #95a0c3;
$default-text-color: #5c465a;
$contact-background: #f7f7f8;
$text-category-color: #6c809d;
$text-subcategory-color: $text-category-color;
$icon-download-color: #95a0c3;
$notification-title-color: #642767;
$notification-subtitle-color: #846d80;
$notification-time-color: #605260;
$notification-star-color: transparent;
$download-bar: $dark;
$download-button: #95a0c3;
$video-details-overlay: #7c6a7e;
$report-number: $dark;
$monitoring-update: $dark;

$font-weight-bold: bold;
$navbar-dark-active-color: $dark;
$login-info-img-thumbnail-height: 1.846rem;

$input-btn-background-color: $primary;
$input-btn-border-color: $hover-color;
$input-btn-color: $grey;
$input-btn-hover-color: $grey;
$input-btn-back-hover-color: $hover-color;
$btn-outline-back-color: transparent;
$btn-outline-back-hover-color: #f8f9fa;
$btn-outline-color: $white;
$btn-outline-hover-color: $dark;

$grayscale-90: #eee;
$gray-text-color-1: #605260;
$default-border-color: #ebe9eb;
$header-bg-color: #fcfcfc;

:root {
  --form-check-label-wrap: pre-wrap;
}

// new colors
$palette-gray-05: #f5f5f5;
$palette-gray-10: #ececec;
$palette-gray-20: #e0e0e0;
$palette-gray-30: #d6d6d1;
$palette-gray-40: #898988;
$palette-gray-50: #585857;
$palette-gray-80: #262624;
$palette-gray-90: #010308;
$palette-blue: #085fff;
$palette-light-blue: #c5d4ef;
$palette-pink: #ff7fde;

// other
$body-color: $palette-gray-90;
$body-bg: $palette-gray-10;
$inverted-text-color: $white;
$bold-text-color: $palette-gray-90;
$card-title-color: $palette-gray-90;
$panel-title-color: $palette-gray-90;

// Main container
$maincontainer-topbar-height: spacing(14);
$maincontainer-header-height: spacing(19);
$maincontainer-header-border: $palette-gray-30;

// Sidebar
$sidebar-width: spacing(58);
$sidebar-header-bg-color: $primary;
$sidebar-base-bg-color: $palette-gray-80;
$sidebar-border-color: $palette-gray-50;
$sidebar-menu-title-text-color: $inverted-text-color;
$sidebar-menu-text-color: $inverted-text-color;
$sidebar-menu-text-active-color: $palette-gray-80;
$sidebar-menu-bg-active-color: $palette-pink;

// bootstraop buttons
$btn-font-size: 16px;
$btn-font-weight: $font-weight-normal;
$btn-font-family: $font-family-regular;

$btn-padding-x: 1rem;
$btn-padding-y: 0.75rem;
$btn-line-height: 0.75rem;

$input-btn-border-width: 1px;
$btn-border-radius: 1px;
