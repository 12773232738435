.app-about {
  padding-bottom: 50px;
  min-height: calc(100vh - 90px);

  &.league {
    height: calc(100vh - 90px);
  }

  .text-truncate {
    @media (max-width: 380px) {
      max-width: 200px;
      display: block;
    }
  }

  .info {
    padding-left: 0.5rem;
    vertical-align: middle;
  }

  .persom-img {
    border: 0.5rem solid #dee2e6;
    margin: 1rem auto;
  }

  .contacts-col {
    margin: 1rem 0;
  }

  .card-body {
    padding: 1.25rem .75rem;

    @media (min-width: 400px) {
      padding: 1.25rem;
    }
  }

  .video {
    width: 90%;
    height: 45vh;
  }



  @media (min-width: 1200px) {
    padding-bottom: 0px;

    .bg-container {
      // background-image: url('./assets/customer-lfp/images/about-bg.png');
      background-repeat: no-repeat;
      background-position: right;
      background-color: white;
      background-size: auto 100%;
      background-color: white;
      height: 100%;
    }

    .title-small.role {
      color: white;
    }


    .contacts-col {
      float: right;
      min-width: 380px;
      margin: 0;
    }



    .persom-img {
      margin: 0;
    }
  }
}
