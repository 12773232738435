.news-page {
  .section {
    font-size: 13px;
    margin-bottom: 20px;

    .color-icon {
      color: grey;
    }

    .table {
      margin: 0;

      thead {
        background-color: $alerts-header;
        color: white;
        font-weight: bold;
        font-size: 12px;
        margin: 2px 0px;

        th {
          height: 35px;
          padding: 0.5rem 0;
        }

        th:first-child {
          border-radius: 4px 0 0 0;
        }

        th:last-child {
          border-radius: 0 4px 0 0;
        }
      }

      tbody {
        background-color: $default_bg_color;
        td {
          font-weight: bold;
          height: 50px;
          vertical-align: middle;
          cursor: default;
          padding: 0 0.4rem 0 0;
        }
      }

      .cell {
        display: flex;
        justify-content: center;
        align-items: center;

        span {
          margin-left: 5px;
        }
      }

      .btn-read {
        color: $campaign-publish_color;
        font-size: 12px;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
        float: right;
      }

      .urgent {
        [class^="icon-"] {
          font-size: 18px;
          color: $primary;
        }
      }
    }

    > div:first-child {
      display: flex;
      height: 50px;
      color: black;
      font-size: 16px;
      font-weight: bold;
      margin: 0 10px 0 5px;
      padding: 10px 0;

      span {
        color: $section-title-color;
        font-size: 20px;
        font-weight: bold;
        line-height: 50px;
        margin: 0 10px 0 5px;
      }
    }

    .no-round {
      height: 60px;
      border-radius: 3px 3px 0 0;
      background-color: $default_bg_color;
      padding: 0px 17px;
      margin-top: 20px;

      span {
        line-height: 50px;
        font-style: italic;
        color: #9b9b9b;
        font-size: 14px;
      }
    }
  }
}

.news-details {
  .back {
    display: flex;
    align-items: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 13px;
    margin: 0px 0px 20px 5px;
    cursor: pointer;
  }
  .icon-arrow-left {
    color: white;
  }
  .content {
    display: flex;
    flex-direction: column;
    background-color: $white;
    width: 100%;
    padding: 60px 40px;
    overflow-y: auto;
    height: calc(100vh - 124px);
    border-radius: 3px;
    box-shadow: 0 1px 0 0 #f2f2f3;

    .title {
      font-size: 40px;
      color: #070b1e;
      font-weight: bold;
    }

    .sub-title {
      font-size: 20px;
      font-weight: bold;
      color: #1f263e;
      margin: 20px 0px 30px 0px;
    }

    .summary {
      font-size: 14px;
      color: #070b1e;
      line-height: 35px;
    }

    img {
      margin: 10px 0px;
    }

    a {
      color: #4a5b75;
      text-decoration: underline;
      font-weight: bold;
    }
  }
}

.news-details-rp {
  .content {
    width: 100%;
    margin-top: 44px;

    .section {
      .title {
        font-size: 20px;
        color: #ffffff;
        font-weight: bold;
        margin-bottom: 25px;
      }

      .info {
        background-color: white;
        box-shadow: 0 1px 0 0 #f2f2f3;
        border-radius: 3px;
        padding: 23px;
        margin-top: 5px;

        .published {
          font-size: 14px;
          color: #070b1e;

          span {
            font-weight: bold;
          }
        }

        hr {
          margin: 23px 0;
        }

        .news-title {
          font-size: 16px;
          color: #070b1e;
          font-weight: bold;
        }

        .btn-read {
          color: $campaign-publish_color;
          font-size: 14px;
          font-weight: bold;
          text-decoration: underline;
          cursor: pointer;
          margin-left: 5px;
        }
      }
    }
  }
}
