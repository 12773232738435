// .container {
//     height: calc(100vh - 56px);
//     overflow-y: auto;
// }

.new-request-container {
    margin-top:15px;
    margin-bottom:15px;

    .create-new-request {
        opacity: 0.8;
        font-size: 13px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.38;
        letter-spacing: 0.5px;
        text-align: left;
        vertical-align: middle;
        color: #11b270;
        cursor: pointer;
      }

    .icon-edit {
        font-size: 18px;
        color: #11b270;
    }

    .new-request-title {
        position: relative;
        top: -3px;
    }
}

.accordion-container {
    .card-body {
        padding: 0;
        background-color: #F1F0F1;
    }
    .card {
        border: none;
        border-left: 1px solid #EBE9EB;
        border-right: 1px solid #EBE9EB;
    }
}