@import "../variables";
@import "../mixins/match";

.app-fixture-details {
  position: relative;
  @include match-header(big);

  .accordion-container {
    font-family: $font-family-secondary;
  }

  .bpp-card {
    background-color: $white !important;

    .card-header {
      background-color: $white;
    }

    &-head {
      font-family: $font-family-secondary;
    }
    &-body {
      font-family: $font-family-secondary;

      .title-small {
        font-weight: bold;
      }
    }

    .title {
      font-size: 24px;
      text-transform: capitalize;
    }

    .title-small {
      font-weight: 700;
    }
  }
  .accordion-title {
    height: 50px;
    align-items: center;
    justify-content: space-between;
    font-family: $font-family-secondary;

    .team-name-div {
      // flex-grow: 1;
      //margin-left: 20px;
      span {
        font-size: 14px;
        font-weight: bold;
        color: $panel-title-color;
        display: block;
        line-height: 1.1;

        @media (min-width: 568px) {
          font-size: 16px;
        }
      }
    }

    .fixture-status {
      flex-grow: 1;
      margin-left: 10px;
      margin-top: 3px;
      color: $palette-gray-50;
    }
  }

  .buttons-section {
    margin-top: 10px;
    float: right;

    @media (min-width: 768px) {
      margin-top: 30px;
    }

    .btn {
      margin-bottom: 10px;
    }
  }

  .info-img {
    height: 32px;
    width: 32px;
    display: block;
    margin: auto;
    @media (min-width: 568px) {
      height: 41px;
      width: 41px;
    }
  }

  .info-text {
    display: inline-block;
    vertical-align: middle;
  }

  .by-user {
    font-size: 12px;
  }

  .read-only-container {
    font-size: 13px;
    color: $gray-text-color-1;

    .info-text-block {
      font-size: 13px;
      color: $gray-text-color-1;
    }
  }

  .info-text-block {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }
  // hr {
  //     margin: 0px -16px;
  // }
  .no-brd .card-header {
    padding-block: spacing(1);
    background-color: transparent;
    border: none;
  }

  .no-brd .card {
    border: none;
  }

  .add-player-btn {
    //min-width: 300px;
    min-width: 100%;
    height: 60px;
    border-radius: 3px; // margin-right: 40px;
    border: solid 1px #ebe9eb;
    cursor: pointer;
    margin: 10px 0;

    .add-player {
      font-size: 36px;
      line-height: 60px;
      float: left;
      margin-left: 12px;
    }

    .player-text {
      display: inline-block;
      line-height: 60px; //margin-left: 15px;
      span {
        font-size: 18px;
        text-align: left;
        color: #37003c;
        margin-left: 12px;

        @media (min-width: 768px) {
          font-size: 24px;
        }
      }
    }
  }

  .players-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .dropdown-menu {
    padding: 0px 0px 0px 14px;
    margin: 0;
  }

  .test-scroll {
    max-height: 420px;
    overflow-y: scroll;
  }

  .test-scroll::-webkit-scrollbar {
    width: 6px;
  }

  .test-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }

  .test-scroll::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
  }

  textarea {
    background-color: #fcfcfc;
  }

  .accordion-container {
    .card-body {
      background-color: #ffffff;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      margin-left: 10px;
      padding: 0;
    }
  }
}

.btn-outline-blue {
  color: $interviews-blue !important;
  border: 1px solid $interviews-blue !important;
  background-color: #ffffff;
  text-transform: uppercase;

  &:hover {
    background-color: $interviews-blue !important;
    border: 1px solid $interviews-blue !important;
    color: #ffffff !important;
  }
}
