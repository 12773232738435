.language-select {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-width: 100%;
    border-radius: 3px;
    border: solid 1px #ebe9eb;
    padding-right: 5px;
  
    @media (min-width: 768px) {
      min-width: 250px;
    }
  
    .language-item {
      height: 20px;
      padding: 0px 7px;
      z-index: 100;
      display: inline-block;
      border-radius: 10px;
      border: solid 1px #ebe9eb;
      background-color: #f1f0f1;
      margin-left: 5px;
  
      span {
        font-size: 12px;
        text-align: left;
        color: #3e0d42;
        vertical-align: middle;
      }
  
      .close-btn {
        cursor: pointer;
        color: $primary-dark;
        font-size: 14px;
        margin: auto;
      }
    }
  
    .add-language {
      height: 40px;
      width: 40px;
      align-items: center;
      border-right: 1px solid #ebe9eb;
  
      span {
        font-size: 26px;
        line-height: 40px;
        margin-left: 7px;
        cursor: pointer;
      }
    }
  }
  