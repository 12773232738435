.app-club-fixture-details {
  position: relative;

  .title-teams {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title-name {
      font-size: 24px;
      font-weight: bold;
      color: $card-title-color;
    }

    .title-vs {
      font-size: 18px;
      color: $title-gray-color;
    }

    .title-icon {
      // display: inline-block;
      height: 32px;
      margin: 0px 10px; // width: 32px;
    }
  }

  .by-user {
    font-size: 12px;
    color: $input-btn-secondary-text;
    line-height: 20px;
    margin-left: 10px;
  }

  .read-only-container {
    display: inline-block;

    .info-text-block {
      margin-left: 50px;
    }

    span {
      display: block;
      font-size: 13px;
      color: $gray-text-color-1;
      margin-top: 14px;

      span {
        display: inline;
      }
    }
  }

  .title-time {
    font-size: 13px;
    color: $title-gray-color;
  }

  .info-block {
    background: $secondary;
  }

  .info-text-block {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  .info-img {
    //height: 41px;
    //width: 41px;
  }

  .info-text {
    display: inline-block;
    vertical-align: middle;
  }

  .middle-text {
    vertical-align: middle;
  }

  .input-control-common {
    width: 160px;
    height: 40px !important;
  }

  .location-control {
    width: 200px;
    height: 40px !important;
  }

  .form-group {
    margin-bottom: 0;
  }

  .margin-right-10 {
    margin-right: 10px;
  }

  .area-icon {
    margin-left: 8px;
    margin-right: 8px;
  }

  .notes-area {
    height: 160px;
    margin-left: 40px;
    width: calc(100% - 40px);
    background-color: $secondary;
    border-color: $input-btn-secondary-border-color;
  }

  .note-container {
    margin-left: 50px;
    width: calc(100% - 50px);
  }

  .datepicker {
    .datepicker-button {
      font-size: 20px;
      padding: 10px;
      background: $input-bg;
      color: $bold-text-color;
      cursor: pointer;
    }
  }

  .ngb-tp-hour {
    input {
      height: 40px;
    }
  }

  .ngb-tp-minute {
    input {
      height: 40px;
    }
  }

  .add-player-btn {
    width: 300px;
    height: 60px;
    border-radius: 3px;
    margin-left: 40px;
    border: solid 1px #ebe9eb;
    cursor: pointer;
    margin-top: 5px;

    .add-player {
      font-size: 36px;
      line-height: 60px;
      float: left;
      margin-left: 12px;
    }

    .player-text {
      display: inline-block;
      line-height: 60px; //margin-left: 15px;
      span {
        font-size: 24px;
        text-align: left;
        color: #37003c;
        margin-left: 12px;
      }
    }
  }

  .players-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .dropdown-menu {
    padding: 0px 0px 0px 14px;
    margin: 0;
  }

  .test-scroll {
    max-height: 420px;
    overflow-y: scroll;
  }

  .test-scroll::-webkit-scrollbar {
    width: 6px;
  }

  .test-scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
  }

  .test-scroll::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
  }

  .language-select {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    min-width: 100%;
    border-radius: 3px;
    border: solid 1px #ebe9eb;
    padding-right: 5px;

    @media (min-width: 768px) {
      min-width: 250px;
    }

    .language-item {
      height: 20px;
      padding: 0px 7px;
      z-index: 100;
      display: inline-block;
      border-radius: 10px;
      border: solid 1px #ebe9eb;
      background-color: #f1f0f1;
      margin-left: 5px;

      span {
        font-size: 12px;
        text-align: left;
        color: #3e0d42;
        vertical-align: middle;
      }

      .close-btn {
        cursor: pointer;
        color: rgba(94, 0, 102, 0.6);
        font-size: 14px;
        margin: auto;
      }
    }

    .add-language {
      height: 40px;
      width: 40px;
      align-items: center;
      border-right: 1px solid #ebe9eb;

      span {
        font-size: 26px;
        line-height: 40px;
        margin-left: 7px;
        cursor: pointer;
      }
    }
  }
}

.dropdown-toggle::after {
    display: none;
}
