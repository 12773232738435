@import '../mixins/request.scss';
@include request();
.app-interviews {


    .brd-b-default {
        border-bottom: 1px solid $default-border-color !important;
    }

    .info-block {
        background: $secondary;
    }

    .info-img {
        height: 32px;
        width: 32px;
        display: block;
        margin: auto;
    }

    .by-user {
        font-size: 12px;
        color: $input-btn-secondary-text;
    }

    .section {
        .section-header {
            padding: 10px 10px 10px 16px;
            margin-top: 0.5rem;
            color: #3e0d42;
            background-color: white;
            border: 1px solid #ebe9eb;
        }

        .fx-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
        }

        .title {
            font-size: 18px;
            font-weight: bold;
        }

        .subtitle {
            color: #3e0d42;
        }

        .th-text {
            font-weight: bold;
            font-size: 0.9rem;
            margin-right: 3rem;
        }

        .text-semi-item {
            color: #948d94;
            font-size: 11px;
            letter-spacing: 0.35px;
            line-height: 18px;
            text-align: center;
        }

        .text-item {
            color: #382738;
            font-size: 12px;
            letter-spacing: 0.75px;
            line-height: 14px;
        }
    }
}
