.app-additional-content-details-league {
  .info-block {
    background: $secondary;
  }

  .overview-container {
    .card {
      border-right: none;
      border-left: none;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0;
    }

    .card-body {
      background-color: $secondary;
      border-bottom: 1px solid $input-btn-secondary-border-color;
      margin-left: 10px;
      padding: 0;
    }
  }

  // CAMERA TYPE
  .readonly-wrapper {
    padding: 20px 10px;

    @media (min-width: 576px) {
      padding: 28px;
    }

    .subtitle {
      color: #6B556C;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 0.69px;
      line-height: 22px;
    }
  }
  .item {
    border: 1px solid #EBE9EB;
    border-radius: 3px;
    margin-bottom: 12px;
    padding: 12px;

    .item-name {
      color: #3E0D42;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 16px;
    }
  }

  .edit-block {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  .info-img {
    height: 32px;
    width: 32px;
    display: block;
    margin: auto;
  }

  .info-text {
    display: inline-block;
    vertical-align: middle;
  }

  .middle-text {
    vertical-align: middle;
  }

  .input-control-common {
    width: 160px;
    height: 40px !important;
  }

  .location-control {
    width: 200px;
    height: 40px !important;
  }

  .form-group {
    margin-bottom: 0;
  }

  .margin-right-10 {
    margin-right: 10px;
  }

  .area-icon {
    //margin-left: 8px;
    //margin-right: 8px;
  }

  .notes-area {
    height: 160px;
    //margin-left: 40px;
    //width: calc(100% - 40px);
    background-color: $secondary;
    border-color: $input-btn-secondary-border-color;
    margin: 2rem 0;
  }


  .notes-area-default {
    height: 160px;
    background-color: $secondary;
    border-color: $input-btn-secondary-border-color;
  }

  .readonly-notes-area {
    margin-left: 40px;
  }

  .note-container {
    margin-left: 50px;
    width: calc(100% - 50px);
  }

  .datepicker {
    .datepicker-button {
      font-size: 20px;
      padding: 10px;
      background: $input-bg;
      color: $bold-text-color;
      cursor: pointer;
    }
  }

  .team-icon {
    display: inline-block;
    height: 33px;
  }

  .team-icon-small {
    display: inline-block;
    height: 20px;
  }

  .overview-container {
    //padding-left: 50px;
    padding-top: 20px;

    .accordion .card {
      margin-bottom: 5px;
      //border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      .card-header {
        border-bottom: none;
      }
    }
  }

  .overview-card {
    .title-small {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .title-large {
    display: inline-block;
    vertical-align: middle;
  }

  .text-small {
    color: $color-default;
  }

  .icon-add {
    vertical-align: middle;
  }

  .broadcaster-string {
    border-color: transparent;
    box-shadow: none;
  }

  .training-info-block {
    .training-icon-block {
      width: 50px;
      display: inline-block;
    }

    .training-info-data-block {
      width: calc(100% - 60px);
      display: inline-block;
    }
  }

  .by-user {
    font-size: 12px;
    color: $input-btn-secondary-text;
  }
}
.app-additional-content-details-club {
  .info-block {
    background: $secondary;
  }

  .overview-container {
    .card {
      border-right: none;
      border-left: none;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 0;
    }

    .card-body {
      background-color: $secondary;
      border-bottom: 1px solid $input-btn-secondary-border-color;
      margin-left: 10px;
      padding: 0;
    }
  }

  // CAMERA TYPE
  .readonly-wrapper {
    padding: 20px 10px;

    @media (min-width: 576px) {
      padding: 28px;
    }

    .subtitle {
      color: #6B556C;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 0.69px;
      line-height: 22px;
    }
  }
  .item {
    border: 1px solid #EBE9EB;
    border-radius: 3px;
    margin-bottom: 12px;
    padding: 12px;

    .item-name {
      color: #3E0D42;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 16px;
    }
  }

  .edit-block {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  .info-img {
    height: 32px;
    width: 32px;
    display: block;
    margin: auto;
  }

  .info-text {
    display: inline-block;
    vertical-align: middle;
  }

  .middle-text {
    vertical-align: middle;
  }

  .input-control-common {
    width: 160px;
    height: 40px !important;
  }

  .location-control {
    width: 200px;
    height: 40px !important;
  }

  .form-group {
    margin-bottom: 0;
  }

  .margin-right-10 {
    margin-right: 10px;
  }

  .area-icon {
    //margin-left: 8px;
    //margin-right: 8px;
  }

  .notes-area {
    height: 160px;
    //margin-left: 40px;
    //width: calc(100% - 40px);
    background-color: $secondary;
    border-color: $input-btn-secondary-border-color;
    margin: 2rem 0;
  }


  .notes-area-default {
    height: 160px;
    background-color: $secondary;
    border-color: $input-btn-secondary-border-color;
  }

  .readonly-notes-area {
    margin-left: 40px;
  }

  .note-container {
    margin-left: 50px;
    width: calc(100% - 50px);
  }

  .datepicker {
    .datepicker-button {
      font-size: 20px;
      padding: 10px;
      background: $input-bg;
      color: $bold-text-color;
      cursor: pointer;
    }
  }

  .team-icon {
    display: inline-block;
    height: 33px;
  }

  .team-icon-small {
    display: inline-block;
    height: 20px;
  }

  .overview-container {
    //padding-left: 50px;
    padding-top: 20px;

    .accordion .card {
      margin-bottom: 5px;
      //border-bottom: 1px solid rgba(0, 0, 0, 0.125);
      .card-header {
        border-bottom: none;
      }
    }
  }

  .overview-card {
    .title-small {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .title-large {
    display: inline-block;
    vertical-align: middle;
  }

  .text-small {
    color: $color-default;
  }

  .icon-add {
    vertical-align: middle;
  }

  .broadcaster-string {
    border-color: transparent;
    box-shadow: none;
  }

  .training-info-block {
    .training-icon-block {
      width: 50px;
      display: inline-block;
    }

    .training-info-data-block {
      width: calc(100% - 60px);
      display: inline-block;
    }
  }

  .by-user {
    font-size: 12px;
    color: $input-btn-secondary-text;
  }
}
