@import "../variables";
@import "../mixins/match";

.app-additional-content-details {
  margin-bottom: 50px;

  .bpp-card {
    background-color: $white !important;

    .card-body {
      font-family: $font-family-secondary;
    }

    .title {
      text-transform: none;
    }

    .title-small {
      font-weight: 700;
    }
  }

  .info-block {
    background: $secondary;
  }

  .info-text-block {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  // INFO (Last Saved)
  .info-img {
    height: 16px;
    width: 16px;
    display: block;
    margin: auto;

    @media (min-width: 568px) {
      height: 18px;
      width: 18px;
    }
  }

  .info-text {
    display: inline-block;
    vertical-align: middle;
  }

  .middle-text {
    vertical-align: middle;
  }

  .location-control {
    width: 200px;
    height: 40px !important;
  }

  .form-group {
    margin-bottom: 0;
  }

  .margin-right-10 {
    margin-right: 10px;
  }

  .notes-area {
    height: 160px;
    background-color: $secondary;
    border-color: $input-btn-secondary-border-color;
  }

  .readonly-notes-area {
    //margin-left: 40px;
  }

  .note-container {
    margin-left: 50px;
    width: calc(100% - 50px);
  }

  .ngb-tp-hour {
    input {
      height: 40px;
    }
  }

  .ngb-tp-minute {
    input {
      height: 40px;
    }
  }

  .icon-edit,
  .icon-edit:hover {
    color: $panel-title-color;
    cursor: pointer;
  }

  .icon-col {
    float: left;
    width: 54px;
  }

  .after-icon-col {
    width: calc(100% - 54px);
  }

  .by-user {
    font-size: 12px;
    color: $input-btn-secondary-text;
    line-height: 20px;
    margin-left: 10px;
  }

  .add-player-btn {
    min-width: 100%;
    height: 60px;
    border-radius: 3px;
    border: solid 1px #ebe9eb;
    cursor: pointer;
    margin: 10px 0;

    .add-player {
      font-size: 36px;
      line-height: 60px;
      float: left;
      margin-left: 12px;
    }

    .player-text {
      display: inline-block;
      line-height: 60px;
      span {
        font-size: 18px;
        text-align: left;
        color: #37003c;
        margin-left: 12px;

        @media (min-width: 768px) {
          font-size: 24px;
        }
      }
    }

    .dropdown-menu {
      margin: 0;
    }

    .test-scroll {
      max-height: 420px;
      overflow-y: scroll;
    }

    .test-scroll::-webkit-scrollbar {
      width: 6px;
    }

    .test-scroll::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
      border-radius: 4px;
    }

    .test-scroll::-webkit-scrollbar-thumb {
      border-radius: 4px;
      -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.5);
    }

    textarea {
      background-color: #fcfcfc;
    }
  }

  .players-content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    max-height: 50vh;
    overflow-y: auto;
  }
}

.app-broadcaster-additional-content-details {
  margin-bottom: 50px;

  .subtitle-text {
    color: #6b556c;
    font-size: 12px;
    letter-spacing: 0.5px;
    line-height: 18px;
  }

  .header {
    // margin-top: 15px;
    padding-bottom: 15px;
    // border-bottom: 1px solid #EDEDED;
  }

  .action-text {
    margin-left: 12px;
    color: black;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 0.75px;
    line-height: 14px;
  }

  .form-check-label {
    opacity: 0.8;
    color: #382738;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.46px;
    line-height: 18px;
  }

  .info-block {
    background: $secondary;
  }

  .info-text-block {
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
  }

  .info-img {
    height: 32px;
    width: 32px;
    display: block;
    margin: auto;
  }

  .info-text {
    display: inline-block;
    vertical-align: middle;
  }

  .middle-text {
    vertical-align: middle;
  }

  .input-control-common {
    width: 160px;
    height: 40px !important;
  }

  .location-control {
    width: 200px;
    height: 40px !important;
  }

  .form-group {
    margin-bottom: 0;
  }

  .margin-right-10 {
    margin-right: 10px;
  }

  .area-icon {
    //margin-left: 8px;
    //margin-right: 8px;
  }

  .notes-area {
    height: 160px;
    //margin-left: 40px;
    //width: calc(100% - 40px);
    background-color: $secondary;
    border-color: $input-btn-secondary-border-color;
    margin: 2rem 0;
  }

  .notes-area-default {
    height: 160px;
    background-color: $secondary;
    border-color: $input-btn-secondary-border-color;
  }

  .note-container {
    margin-left: 50px;
    width: calc(100% - 50px);
  }

  // CAMERA TYPE
  .readonly-wrapper {
    padding: 20px 10px;

    @media (min-width: 576px) {
      padding: 28px;
    }

    .subtitle {
      color: #6b556c;
      font-size: 11px;
      font-weight: bold;
      letter-spacing: 0.69px;
      line-height: 22px;
    }
  }

  .item {
    border: 1px solid #ebe9eb;
    border-radius: 3px;
    margin-bottom: 12px;
    padding: 12px;

    .item-name {
      color: #3e0d42;
      font-size: 13px;
      font-weight: bold;
      letter-spacing: 0.5px;
      line-height: 16px;
    }
  }

  .datepicker {
    .datepicker-button {
      font-size: 20px;
      padding: 10px;
      background: $input-bg;
      color: $bold-text-color;
      cursor: pointer;
    }
  }

  .team-icon {
    display: inline-block;
    height: 33px;
  }

  .team-icon-small {
    display: inline-block;
    height: 24px;
  }

  .overview-container {
    padding-top: 20px;

    .accordion .card {
      margin-bottom: 5px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
  }

  .overview-requests {
    padding-top: 50px;
  }

  .overview-card {
    .title-small {
      display: inline-block;
      vertical-align: middle;
    }
  }

  .inline-middle {
    display: inline-block;
    vertical-align: middle;
  }

  .text-small {
    color: $color-default;
  }

  .icon-add {
    vertical-align: middle;
  }

  .broadcaster-string {
    border-color: transparent;
    box-shadow: none;
  }

  @media (min-width: 768px) {
    .add-button-new {
      position: relative;
      top: 20px;
    }

    .add-button-small {
      position: relative;
      top: 24px;
    }

    .or-text {
      /* position: relative;
            top: -7px; */
      margin-right: 10px;
      margin-left: 10px;
    }
  }

  .add-button-new {
    cursor: pointer;
  }

  .add-button-small {
    cursor: pointer;
    font-size: 16px;
  }

  .add-button-text {
    position: relative;
    top: -7px;
  }

  select.form-control {
    height: unset !important;
  }

  .by-user {
    font-size: 12px;
    color: $input-btn-secondary-text;
    line-height: 20px;
  }
}
