.app-dashboard-message {
  //position: relative;
  .header {
    .image {
      // width: 35px;
      //display: inline-block;
      //.img-thumbnail {
      //    width: 28px;
      //    height: 28px;
      //}
    }

    .title {
      //color: #3e0d42;
      //font-size: 14px;
      //font-weight: bold;
      //display: inline-block;
      //vertical-align: middle;
      //margin-left: 16px;
    }

    .title-wrap {
      // @media (max-width: 768px) {
      //   padding-right: 80px;
      // }
    }

    .hour {
      // float: right;
      // line-height: 30px;
      // font-size: 13px;
      // font-weight: normal;
      // color: #605260;
      // margin-bottom: -42px;
    }
  }

  .body {
    //margin-left: 48px;
    //margin-top: -10px;
    //.sub-title {
    //    font-size: 13px;
    //    font-weight: normal;
    //    text-align: left;
    //    color: #605260;
    //    line-height: 30px;
    //}
    //.status {
    //    font-size: 13px;
    //    font-weight: bold;
    //    text-align: left;
    //    color: #605260;
    //    line-height: 30px;
    //}
  }
  
  .img-thumbnail {
    background-color: transparent;
  }

  .message-content {
    height: auto;
    border: solid 1px rgba(68, 68, 68, 0.1);
    border-radius: 3px;

    .table {
      margin-bottom: 0;
      height: 100%;

      td {
        vertical-align: middle;
        border: 0;
        white-space: nowrap;

        .team-icon {
          height: 20px;
        }

        .point {
          display: inline-block;
          margin-top: 5px;
        }

        .team {
          vertical-align: middle;
          font-size: 13px;
          font-weight: bold;
          text-align: left;
          color: #3e0d42;
        }

        .time {
          font-size: 12px;
          color: #6b556c;
          text-align: left;
        }

        .btn-outline-secondary {
          color: #382738;
          background-color: transparent;
          background-image: none;
          border-color: #ebe9eb;

          &:hover {
            background-color: rgba(41, 187, 115, 0.3);
          }
        }
      }
    }

    .info-item {
      margin-right: 16px;

      .parameter {
        font-size: 11px;
        color: #6B556C;
      }

      .statistic {
        font-size: 11px;
        color: #6B556C;
        font-weight: bold;
      }
    }
  }
}


@media (max-width: 768px) {
  .dashboard-icon {
    //display: block;
    //position: absolute;
    //left: 0;
    //top: 0;
  }
  .app-dashboard-message .body {
    //margin-left: 0;
    //margin-top: 30px;
  }
  .app-dashboard-message .header .title {
   // margin-left: 0;
   // position: absolute;
   // padding-left: 43px;
   // padding-top: 0;
   // left: 0;
   // top: 0;
   // line-height: 1;
   // display: flex;
   // align-items: center;
   // justify-content: center;
   // height: 32px;

  }
  .app-dashboard-message .header .hour {
   // display: block;
   // position: absolute;
   // right: 0;
   // top: -20px;
   // width: 80px;
   // font-size: 11px;
  }
}
