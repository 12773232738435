$interviews-light-gray: #E0E0E0;
$interviews-alt-light-gray: #ECECEC;
$interviews-table-gray: #F5F5F5;
$interviews-dark-gray: #262624;
$interviews-gray: #585857;
$interviews-blue: #085FFF;
$interviews-scroll-blue: #b4ceff;
$interviews-pink: #FF7FDE; 
$interviews-btn-submitted: #FADDB3;
$interviews-btn-submitted-outline: #ED8E00;
$interviews-btn-approved: #DAF2B7;
$interviews-btn-approved-outline: #83D510;
$interviews-btn-rejected: #FBCCD2;
$interviews-btn-rejected-outline: #ED001C;
$interviews-btn-draft: rgb(148, 148, 141);
$interviews-btn-draft-outline: rgb(57, 57, 57);