.custom-picker {
  height: 40px;
  border: 1px solid #EBE9EB;
  border-radius: 3px;
  background-color: #FCFCFC; // cursor: pointer;

  .dropdowm-toggle::after {
    display: none;
  }


  .place-holder {
      color: #605260;
      font-size: 13px;
      margin-left: 9px;
  }

  .front {
      span {
          line-height: 40px;
      }

      .arrow {
          float: right;
          font-size: 14px;
          color: #5e0066;
          margin-right: 9px;
      }
  }

  .dropdown-menu {

      min-width: 64px;
      max-height: 14rem;
      overflow-y: auto;
      background-color: #FFFFFF;
      padding: 0;
      border-radius: 0px;
      hr {
          margin: 0;
      }
  }

  .drop-menu-item {
      padding: 0.5rem;
      cursor: pointer;
      &:hover {
        // background-color: rgba(41, 187, 115, 0.1)
      }

  }

}

.invalid {
  border-color: red;
}
