@import '../../global/colors';
.common-search-container {
  .search-label {
    font-size: 13px;
  }

  .search-picker {
    max-width: 300px;
    background-color: $interviews-blue;

    cursor: pointer;

    .select-place-holder {
      color: white;
      font-weight: 400;
      font-size: 13px;
      text-transform: uppercase;
    }

    .front {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0.25rem 1.5rem;

      .arrow {
        float: right;
        font-size: 14px;
        color: white;
        margin-right: 9px;
      }
    }

    .dropdown-menu {
      width: 100%;
      background-color: #FFFFFF;
      margin: -3px -1px;
      padding: 0;
      border-radius: 0px;
      max-height: calc(100vh - 156px);
      overflow-y: auto;
      min-width: 100% !important;
      background-attachment: local, local, scroll, scroll;

      span {
        //line-height: 40px;
      }

      span.icon-edit {
        float: right;
        color: #490050;
        font-size: 20px;
        cursor: pointer;
      }

      hr {
        margin: 0;
      }

      & > div {
        padding: 0px 9px;
      }

      .add-span {
        color: #29BB73;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
}
