@import '../../styles/global/colors';
.table-bpp {
  @extend .table;
  font-size: 12px;
  background-color: white;
  border-radius: 3px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);

  @media (min-width: 568px) {
    font-size: 13px;
  }

  .title {
    color: $card-title-color;
    font-size: 16px;

    @media (min-width: 568px) {
      font-size: $card-title-font-size;
    }
  }

  .subtitle {
    font-size: 12px;
    color: #6b556c;
  }

  .past-items {
    opacity: 0.8;
  }

  .nowrap {
    white-space: nowrap;
  }

  .padding-right {
    padding-right: 30px;
  }

  @media (max-width: 576px) {
    td, th {
      padding: .75rem 0 .75rem .5rem;
    }
  }

  td {
    vertical-align: middle;
  }

  th {
    color: black;
    vertical-align: middle !important;
    line-height: 1.2; // When mobile
    border-bottom: 2px solid $interviews-blue !important;
  }
  .icon-arrow-right {
    font-size: 15px;
    @media (min-width: 568px){
      font-size:24px;
    }
  }
  .cell-width-auto {
    white-space: nowrap;
    width: 1%;
  }

  .text-bold {
    font-weight: $bold;
    color: $bold-text-color;
  }

  .text-bold-default {
    font-weight: $bold;
  }

  .right-text {
    text-align: right;
  }

  .row-clickable {
    cursor: pointer;
  }

  .row-not-clickable {
    cursor: default;
  }

  .team-icon {
    height: 20px;
  }
}

.table-bpp-report {
  @extend .table-bpp;
  box-shadow: unset;

  th, td {
    border-bottom: 1px solid #dee2e6;
    border-top: unset;
  }

  th span {
    @media (max-width: 576px) {
      max-width: 42px;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: auto;
    }
  }

  .orderAsc {
    position: relative;
    margin-right: 15px;

    .triangle {
      position: absolute;
      top: 5px;
      margin-left: 4px;
      width: 0px;
      height: 0px;
      border-width: 0px 5px 5px;
      border-style: solid;
      border-color: transparent transparent $primary;
      border-image: initial;
    }
  }

  .orderDesc {
    position: relative;
    margin-right: 15px;

    .triangle {
      position: absolute;
      top: 5px;
      margin-left: 4px;
      width: 0px;
      height: 0px;
      border-width: 5px 5px 0px;
      border-style: solid;
      border-color: $primary transparent transparent;
      border-image: initial;
    }
  }
}
.gray-background {
  background-color: $interviews-table-gray
}