.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-grow-4 {
  flex-grow: 4;
}

.flex-grow-5 {
  flex-grow: 5;
}

.col-icon {
  min-width: 8.333333%;
}

.top16 {
  margin-top: 16px;
}

.top24 {
  margin-top: 24px;
}

.top32 {
  margin-top: 32px;
}

.top48 {
  margin-top: 48px;
}

.bottom8 {
  margin-bottom: 8px;
}

.bottom16 {
  margin-bottom: 16px;
}

.bottom24 {
  margin-bottom: 24px;
}

.page-center {
  position: absolute;
  top: 50%;
  left: 50%;

  .fx-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .fx-title {
      color: #3e0d42;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.5px;
      min-width: 200px;
    }
  }
}

.debug-block {
  font-size: 12px;
  background-color: lightgray;
}

.ngx-pagination .current {
  background: $primary !important;
}

.no-border {
  border: none;
}

.brd-b {
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}

.brd-t {
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.accordion-container {
  button.btn-link {
    width: 100%;
    padding: 0px;
    height: 100%;
    border-radius: 0;
    border: none;
    // border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  }

  .btn-link:hover,
  .btn-link:focus,
  .btn-link.focus {
    text-decoration: none;
    background-color: unset;
    box-shadow: none;
  }
}

.ngb-tp-input {
  padding: 0;
  height: 3rem;
}

.custom-tooltip-1 {
  .tooltip-inner {
    background-color: white;
    border-radius: 0%;
    border: 1px solid black;
    color: black;
  }

  .arrow {
    display: none;
  }
}
