@import '../../../styles/global/colors';
.persons-report {

  .dropdown-menu {
    min-width: 7rem;
  }

  .card-header {
    padding: 10px 16px;
    align-items: center;

    .pr-header {
      //line-height: 46px;
    }
  }
  // CLUB PICKER
  .club-picker {
    min-width: 240px;
    width: 100%;
    cursor: pointer;
    padding: 4px;
    background-color: $interviews-blue;
    border: none !important;

    @media (min-width: 768px) {
      border-bottom: none;
    }

    .select-place-holder {
      color: white;
      font-size: 13px;
      //margin-left: 9px;
    }

    .front {
      min-width: 240px;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        //line-height: 40px;
      }

      .arrow {
        float: right;
        font-size: 14px;
        color: white;
        margin-right: 9px;
      }
    }

    .dropdown-menu {
      min-width: 240px;
      width: 100%;
      background-color: #FFFFFF;
      margin: -3px -1px;
      padding: 0;
      border-radius: 0px;
      border-top: 2px solid #29BB73; // border-top-color: #29BB73;
      max-height: 70vh;
      overflow-y: auto;
      span {
        //line-height: 40px;
      }

      span.icon-edit {
        float: right;
        color: #1b2875;
        font-size: 20px;
        cursor: pointer;
      }

      hr {
        margin: 0;
      }

      & > div {
        padding: 0px 9px;
      }

      .add-span {
        color: #29BB73;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
  // REPORT PICKER
  .report-picker {
    //height: 40px;
    //width: 75px;
    //display: inline-block;
    .icon-file,
    .icon-file:hover {
      position: relative;
      top: 3px;
      color: white;
    }

    .front {
      width: 80px;
      display: inline-block;
      cursor: pointer;

      span {
        line-height: 40px;
      }

      .arrow {
        float: right;
        font-size: 14px;
        color: $primary;
        margin-right: 9px;
      }
    }

    .dropdown-menu {
      width: 70px;
      background-color: #FFFFFF;
      margin: -3px -1px;
      padding: 0;
      border-radius: 0px;
      border-top: 2px solid #29BB73; // border-top-color: #29BB73;
      span {
        line-height: 40px;
      }

      span.icon-edit {
        float: right;
        color: #1b2875;
        font-size: 20px;
        cursor: pointer;
      }

      hr {
        margin: 0;
      }

      & > div {
        padding: 0px 9px;
      }

      .add-span {
        color: #29BB73;
        font-weight: bold;
        font-size: 12px;
      }
    }
  }
  // TABLE
  th {
    cursor: pointer;
  }
}
.table-margin-value {
  padding: 0px !important;
}