@import "../../../styles/global/colors";
// MATCH
@mixin match-header($size: small) {
  .title-teams {
    display: flex;
    flex-direction: column;

    @media (min-width: 1024px) {
      flex-direction: row;
      align-content: center;
      //justify-content: space-between;
      //padding: 10px 0 0;
    }

    > div {
      display: flex;
      align-items: center;

      @media (min-width: 1024px) {
        flex-basis: 48%;
      }
    }
    // HOME TEAMS TABLET
    .title-teams__home {
      @media (min-width: 1024px) {
        flex-direction: row-reverse;

        .title-name {
          margin: 0 12px 0 0;
        }
      }
    }

    .title-name {
      line-height: 1.1;
      font-weight: bold;
      color: black;
      text-transform: uppercase;

      @if $size == "big" {
        font-size: 18px;
      }

      @if $size == "small" {
        font-size: 14px;
      }

      @media (min-width: 568px) {
        margin: 0 0 0 12px;

        @if $size == "big" {
          font-size: 24px;
        } @else {
          //font-size: 16px;
        }
      }
    }

    .title-vs {
      align-self: flex-end;
      display: flex;
      text-align: center;
      justify-content: center;
      margin: 3px 0px;
      font-size: 14px;
      //font-weight: bold;
      //background: #5d0666;
      //color: white;
      width: 30px;
      border-radius: 3px;

      @media (min-width: 1024px) {
        margin: 10px 1%;
        font-size: 16px;
        //color: $title-gray-color;
      }
    }

    .title-icon {
      height: 30px;

      @media (min-width: 576px) {
        //height: 40px;
      }
    }
  }

  .title-time {
    text-align: right;
    font-family: $font-family-secondary;
    //font-size: 12px;
    //font-weight: bold;
    @media (min-width: 1024px) {
      text-align: center;
    }
  }
  // DETAIL
  .title-icon {
    height: 30px;
    text-align: center;
    display: block;
    width: auto;
    margin-right: 0.5rem;

    @media (min-width: 1024px) {
      margin: 0;
    }
  }
}
