@import "variables";
@import "icons";
@import "fontfaces";
@import "~bootstrap/scss/bootstrap";
@import "../global/mixins/match.scss";
@import "../global/mixins/request.scss";

/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b4ceff;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7598da;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: -1;
}
@import "components/login-info.scss";
@import "components/languages-picker.scss";
@import "components/main-layout.scss";
@import "common.scss";
@import "datepicker.scss";
@import "dropdown.scss";
@import "items.scss";
@import "tables.scss";
@import "components/sidebar.scss";
@import "components/club-training-details.scss";
@import "components/dashdoard.message.scss";
@import "components/dashboard-rp.scss";
@import "components/league-training-details.scss";
@import "components/broadcaster-training-details.scss";
@import "components/about.scss";
@import "components/persons-report.scss";
@import "components/fixtures-table.scss";
@import "components/club-fixture-details.scss";
@import "components/fixture-details.scss";
@import "components/player-manager.scss";
@import "components/user-profile.scss";
@import "components/filming.scss";
@import "components/interviews.scss";
@import "components/location-picker.scss";
@import "components/common-search-page.scss";
@import "components/additional-content-details.scss";
@import "components/league-additional-content-details.scss";
@import "components/fixtures-wrapper.component.scss";
@import "components/news.scss";
@import "components/modal.scss";
// @import 'components/filming.scss';
